export const addToCalendarSk = {
	cancel: 'Zrušiť',
	close: 'Zatvoriť',
	'date.status.cancelled': 'Tento dátum bol zrušený.',
	'date.status.cancelled.cta': 'Prosím, aktualizujte svoj kalendár!',
	'label.addtocalendar': 'Pridať do kalendára',
	'label.icalfile': 'iCal súbor',
	'modal.button.default': 'Klikni sem',
	'modal.clipboard.text': '[strong]Postup:[/strong]',
	'modal.crios.ical.h': 'Pozor',
	'modal.crios.ical.steps': '[ol][li]Otvorte prehliadač Safari[/li][li]Vložte a otvorte odkaz, ktorý sme vám automaticky skopírovali[/li][li]Následne sa vám automaticky otvorí pridanie udalosti do kalendára[/li][/ol]',
	'modal.crios.ical.text': 'Tento priehliadač na iOS nevie otvoriť kalendárový súbor.',
	'modal.multidate.h': 'Toto je séria udalostí',
	'modal.multidate.text': 'Pridajte udalosti jednu po druhej',
	'modal.subscribe.yahoo.h': 'Pridať kalendár do Yahoo',
	'modal.subscribe.yahoo.text': '[ol][li]Otvorte Yahoo kalendár.[/li][li]Kliknite na "Actions" tab.[/li][li]Kliknite na "Follow Other Calendars".[/li][li]Nastavte meno a skopírujte obsah schránky do poľa s URL.[/li][/ol]',
	'modal.webview.ical.h': 'Pozor',
	'modal.webview.ical.steps': '[ol][li]Otvorte prehliadač Safari[/li][li]Vložte a otvorte odkaz, ktorý sme vám automaticky skopírovali[/li][li]Následne sa vám automaticky otvorí pridanie udalosti do kalendára[/li][/ol]',
	'modal.webview.ical.text': 'Tento priehliadač na iOS nevie otvoriť kalendárový súbor.',
}
