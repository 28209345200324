/* eslint-disable vue/sort-keys */

import { createStore } from 'vuex'
import { User } from '@/models/user'
import { AxiosError } from 'axios'
import { Barber, Category, Order, Service, Store } from '@/models/order'
import { OrderReservation } from '../models/order'
import { Times } from '@/models/times'
import dayjs from 'dayjs'
import i18n from '@/plugins/i18n'
import { setLocale } from '@vee-validate/i18n'
// import PlayerZero from '@goplayerzero/sdk-web'
import { API } from '@/plugins/api'

export interface State {
  barbers: Barber[],
  categories: Category[],
  creatingNewOrder: boolean,
  inputFocused: boolean,
  isIosPwa: boolean,
  loadingTimes: boolean,
  locale: string,
  order: Order,
  orderReservations: OrderReservation[],
  selectedCategory: Category | null,
  services: Service[], 
  stores: Store[],
  times: Times,
  token: string,
  user: User
  utm: Record<string, string>
}

const state = () => {
  const locale = window.localStorage.getItem('gw_locale') || i18n.global.locale.value
  i18n.global.locale.value = locale as 'sk' | 'en'

  return {
    barbers: [],
    categories: [],
    creatingNewOrder: false,
    inputFocused: false,
    isIosPwa: false,
    loadingTimes: false,
    locale,
    order: JSON.parse(window.localStorage.getItem('gw_order') || 'null') as Order || new Order(),
    orderReservations: [],
    selectedCategory: JSON.parse(window.localStorage.getItem('gw_selected_category') || 'null') as Category,
    services: [],
    stores: [],
    times: {},
    token: window.localStorage.getItem('gw_user_token') || '',
    user: JSON.parse(window.localStorage.getItem('gw_user') || 'null') as User || new User(),
    utm: JSON.parse(window.sessionStorage.getItem('gw_session_utm') || '{}')
  }
}

const store = createStore<State>({
	state,
	mutations: {
    cancelledOrderReservation(state, reservation: OrderReservation) {
      const cancelledIndex = state.orderReservations.findIndex(order => order.id === reservation.id)
      state.orderReservations.splice(cancelledIndex, 1, reservation)
    },

    detectedIosPwa(state, isIosPwa: boolean) {
      state.isIosPwa = isIosPwa
      if (isIosPwa) {
        window.visualViewport?.addEventListener('resize', () => {
          // const viewport = (event.target as VisualViewport)
          // document.documentElement.style.height = `${viewport.height}px`

          if (state.inputFocused) {
            const content = document.querySelector('ion-content')
            if (content) {
              setTimeout(() => {
                content.style.setProperty('--keyboard-offset', '0px')
              }
              , 300)
            }
          }
        })

        window.addEventListener('scroll', (e) => {
          if (state.inputFocused) return
          e.preventDefault()
          window.scrollTo(0, 0)
        })
      }
    },

    inputFocusChanged(state, focused: boolean) {
      state.inputFocused = focused
    },

    loggedOutUser(state) {
      state.user = new User()
      window.localStorage.removeItem('gw_user')

      state.token = ''
      window.localStorage.removeItem('gw_user_token')

      state.order = new Order()
      window.localStorage.removeItem('gw_order')

      state.selectedCategory = null
      window.localStorage.removeItem('gw_selected_category')
    },

    startedNewOrder(state) {
      state.creatingNewOrder = true
    },

    finishedNewOrder(state) {
      state.creatingNewOrder = false
    },

    startedLoadingTimes(state) {
      state.loadingTimes = true
    },

    finishedLoadingTimes(state) {
      state.loadingTimes = false
    },

    updatedBarbers(state, barbers: Barber[]) {
      state.barbers = barbers
    },

    updatedOrder(state, order: Partial<Order>) {
      state.order = { ...state.order, ...order } 
      window.localStorage.setItem('gw_order', JSON.stringify(state.order))
    },

    updatedOrderReservations(state, orderReservations: OrderReservation[]) {
      state.orderReservations = orderReservations
    },

    updatedStores(state, stores: Store[]) {
      state.stores = stores
    },

    updatedServices(state, services: Service[]) {
      state.services = services
    },

    updatedCategories(state, categories: Category[]) {
      state.categories = categories
    },

    selectedCategory(state, category: Category) {
      state.selectedCategory = category
      category ? 
        window.localStorage.setItem('gw_selected_category', JSON.stringify(category)) : 
        window.localStorage.removeItem('gw_selected_category')
    },

    updatedTimes(state, times: Times) {
      state.times = times
    },
    
    updatedToken(state, token: string) {
      state.token = token || ''
      window.localStorage.setItem('gw_user_token', state.token)
    },

		updatedUser(state, user: Partial<User>) {
      state.user = { ...state.user, ...user } 
      window.localStorage.setItem('gw_user', JSON.stringify(state.user))
    },

    updatedLocale(state, locale: string) {
      state.locale = locale
      setLocale(locale)
      window.localStorage.setItem('gw_locale', locale)
    },

    updatedUtm(state, utm: Record<string, string>) {
      state.utm = { ...state.utm, ...utm }
      window.sessionStorage.setItem('gw_session_utm', JSON.stringify(state.utm))
    }
	},

	actions: {
    async login(store, data: { login: string, password: string }) {
      try {
        const { data: { response } } = await API.auth.login(data)
        if (response) {
          store.commit('updatedUser', response.user)
          store.commit('updatedToken', response.token)
          // if (store.state.user.id)
          //   PlayerZero.identify(`${store.state.user.id}`, {
          //     name: `${store.state.user.name} ${store.state.user.surname}`,
          //     email: store.state.user.email
          //   })
        }
        return response
      } catch (err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    async logout(state, invalidate = true) {
      if (!state.getters.isLoggedIn) {
        return
      }
      
      try {
        if (invalidate) await API.auth.invalidate()
      } catch (err) {
        return Promise.reject(err)
      } finally {
        state.commit('loggedOutUser')
      }
    },
    
    async getUser(state) {
      try {
        const user = await API.auth.info.get()
        if (user.data) {
          state.commit('updatedUser', user.data)
        }
        return user.data
      } catch (err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    async getStores(state) {
      if (state.state.stores?.length) {
        return state.state.stores
      }

      try {
        const { data: { data: stores = [] }} = await API.stores()
        state.commit('updatedStores', stores)
        return stores
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    async getServices(state) {
      const { store } = state.state.order
      if (!store) return

      try {
        const { data: { data: services = [] }} = await API.services(store.id)
        state.commit('updatedServices', services)
        return services
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    async getCategories(state) {
      const { store } = state.state.order
      if (!store) return

      try {
        const { data: { data: categories = [] }} = await API.categories(store.id)
        state.commit('updatedCategories', categories)
        return categories
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    async getBarbers(state) {
      const { store, service } = state.state.order
      if (!service || !store) return []

      try {
        const { data: { data: barbers = [] }} = await API.barbers(store.id, service.id)
        state.commit('updatedBarbers', barbers)
        return barbers
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      }
    },
    
    async getTimes(state, data?: { month: number; year: number; }) {
      const { service, store, barber, date } = state.state.order
      if (state.state.loadingTimes || !service || !store || !barber || (!data && !date)) {
        return []
      }
      
      try {
        state.commit('startedLoadingTimes')
        const dateString = data ? 
          dayjs().set('month', data.month - 1).set('year', data.year).format('YYYY-MM') : 
          dayjs(date).format('YYYY-MM-DD')

        const { data: { data: times = [] }} = await API.times(
          store.id, service.id, barber.id, dateString
        )
        state.commit('updatedTimes', times)
        return times
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      } finally {
        state.commit('finishedLoadingTimes')
      }
    },

    async getOrderReservations(state) {
      try {
        const { data: { data: orders }} = await API.user.orders.list()
        state.commit('updatedOrderReservations', orders)
      } catch(err) {
        return Promise.reject((err as AxiosError).response)
      }
    },

    startNewOrder(state, order: Order) {
      state.commit('updatedOrder', order)
      state.commit('startedNewOrder')
    },

    finishNewOrder(state) {
      state.commit('updatedOrder', new Order())
      state.commit('selectedCategory', null)
      state.commit('finishedNewOrder')
    }
  },

  getters: {
    isLoggedIn(state): boolean {
      return !!(state.user.id && state.token)
    }
  },
})

export default store
