import { ref } from 'vue'

export function useFixedBottom() {
	const fixedPaddingBottom = ref('0px')
	const onFixedBottomResize = (size: number) => fixedPaddingBottom.value = `${size}px`

	return {
		fixedPaddingBottom,
		onFixedBottomResize
	}
}
