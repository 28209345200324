import { User } from '@/models/user'
import { GWAnalyticsProvider } from '@/plugins/analytics'
import { TGWAnalyticsEvent, TGWAnalyticsEventPayload } from '@/plugins/analytics/types'

import { GtmSupport } from '@gtm-support/vue-gtm'

export class GtmAnalyticsProvider implements GWAnalyticsProvider {
	gtm: GtmSupport
	private user?: User

	constructor(gtm: GtmSupport) {
		this.gtm = gtm
	}

	track<T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T>): void {
		let typedData
		switch(event) {
			case 'click':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'click'>
				this.gtm.trackEvent({
					event, 
					label: typedData.name,
					language: typedData.language,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
					user_id: this.user?.id,
					value: typedData.value
				})
				break

			
			case 'contact_click':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'contact_click'>
				this.gtm.trackEvent({
					event, 
					language: typedData.language,
					number: typedData.number,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
					type: typedData.type,
					user_id: this.user?.id,
				})
				break

			case 'form_filled': 
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'form_filled'>
				this.gtm.trackEvent({ 
					event, 
					label: 'Order form filled',
					language: typedData.language,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
					user_id: this.user?.id
				})
				break

			case 'input_error': 
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'input_error'>
				this.gtm.trackEvent({ 
					event, 
					label: `Input ${typedData.input_name} error`,
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: this.user?.id,
					value: typedData.value
				})
				break

			case 'input_filled': 
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'input_filled'>
				this.gtm.trackEvent({ 
					event, 
					label: typedData.name,
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: this.user?.id,
					value: typedData.value
				})
				break

			case 'input_focused': 
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'input_focused'>
				this.gtm.trackEvent({ 
					event, 
					label: typedData.name,
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: this.user?.id
				})
				break

			case 'login':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'login'>
				if (typedData.user) this.user = typedData.user
				this.gtm.trackEvent({ 
					event, 
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: typedData.user.id
				})
				break

			case 'logout':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'logout'>
				this.user = undefined
				this.gtm.trackEvent({ 
					event, 
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: typedData.user.id
				})
				break

			case 'order_created':
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'order_created'>
				this.gtm.trackEvent({
					event,
					label: 'Order created',
					language: typedData.language,
					order_barber_id: typedData.order.barber.id,
					order_barber_name: `${typedData.order.barber.first_name} ${typedData.order.barber.last_name}`,
					order_barber_service_time: typedData.order.duration,
					order_id: typedData.order.id,
					order_note: typedData.order.note,
					order_service_id: typedData.order.service.id,
					order_service_name: typedData.order.service.name,
					order_service_price: typedData.order.service_price,
					order_slot_date: new Date(typedData.order.reservation_datetime).toISOString(),
					order_store_id: typedData.order.store.id,
					order_store_name: typedData.order.store.name,
					order_user_id: typedData.order.user?.id,
					order_user_is_client: typedData.order.user?.is_guest,
					order_user_marketing: typedData.order.user?.marketing_agreement,
					order_user_new_account: typedData.order.user?.create_account,
					order_user_vop: typedData.order.user?.tos_agreement,
					page: typedData.page,
					platform: typedData.platform,
				})
				break

			case 'page_view':
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'page_view'>
				this.gtm.trackView(
					typedData.page, 
					typedData.path, 
					{ 
						language: typedData.language, 
						platform: typedData.platform,
						user_id: this.user?.id
					}
				)
				break

			case 'signup':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'signup'>
				this.gtm.trackEvent({ 
					event, 
					language: typedData.language,
					page: typedData.page,
					platform: typedData.platform,
					user_id: typedData.user.id
				})
				break
			
			case 'step_view':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'step_view'>
				this.gtm.trackEvent({
					event: 'click', 
					label: `${typedData.step_number}.step - ${typedData.step_name}`,
					language: typedData.language,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
					user_id: this.user?.id,
					value: typedData.value
				})
				break
			default: return
		}
	}
}