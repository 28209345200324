import { getPlatforms } from '@ionic/vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { GWAnalyticsBasePayload } from '../types'

export function useGWAnalyticsBasePayload() {
	const { locale } = useI18n()
	const route = useRoute()

	const payload = computed<GWAnalyticsBasePayload>(() => ({
		language: locale.value,
    page: route.name?.toString() || '',
    path: route.fullPath,
    platform: getPlatforms().join(', ' )
	}))

	return {
    payload
  }
}