import { useEventBus } from '@vueuse/core'
import { ref } from 'vue'
import { useGrid } from 'vue-screen'
const grid = useGrid('tailwind')
const modalEventBus = useEventBus<'open' | 'close' | 'closed', {  data?: any, type: ModalType }>('resetEventBus')
const closedHooks = ref<Map<ModalType, (...payload: any) => Promise<any>>>(new Map([]))

export type ModalType = 'login' | 'summary' | 'reset' | 'set-password' | 'order-success' | 'calendar' | 'menu' | 'order-evaluated' | 'order-reservation-detail' | 'order-cancel' | 'order-evaluation' | 'order-continue' | 'cancel-order-reasons'

modalEventBus.on(async (event, payload) => {
	if (
		((!grid.breakpoint && event === 'closed') || (grid.breakpoint && event === 'close'))
		&& payload && closedHooks.value.has(payload.type)
	) {
		const hook = closedHooks.value.get(payload.type)
		if (hook && (payload.data?.hookCondition === undefined || payload.data.hookCondition)) hook(payload.data)
		closedHooks.value.delete(payload.type)
	}

	if (event === 'closed' && payload?.data) {
		if (payload.data?.detail?.role === 'backdrop') {
			document.dispatchEvent(new CustomEvent('gwModalDismissedByBackdrop', { detail: null }))
		}
	}
})

export function useEvents() {
	const openModalEvent = async (type: ModalType, data?: any) => {
		modalEventBus.emit('open', { data, type })
	}

	const getClosedHook = (type: ModalType) => {
		return closedHooks.value.get(type)
	}

	const addClosedHook = (type: ModalType, closedHook: (...payload: any) => Promise<any>) => {
		closedHooks.value.set(type, closedHook)
	}

	return {
		addClosedHook,
		getClosedHook,
		modalEventBus,
		openModalEvent
	}
}