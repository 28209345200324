import { modalController, createAnimation, Animation } from '@ionic/vue'
import GwModal from './GwModal.vue'
import GwBottomSheet from './GwBottomSheet.vue'
import { App, h, inject } from 'vue'

export interface GwPluginInterface {
  closeModal: (id: string) => Promise<void>;
  closeTop: (data?: any) => Promise<void>;
  enterAnimationDefinition: (baseEl: any) => Animation
  getTop: () => Promise<HTMLIonModalElement>
  leaveAnimationDefinition: (baseEl: any) => Animation
  openBottomSheet: (
    innerComponent: any,
    props?: {
      canDismiss?: boolean | ((data?: any, role?: string | undefined) => Promise<boolean>);
      cssClass?: string;
      data?: any;
      size?: string;
    },
    id?: string
  ) => Promise<HTMLIonModalElement>;
  openModal: (
    innerComponent: any,
    props?: {
      canDismiss?: boolean | ((data?: any, role?: string | undefined) => Promise<boolean>);
      cssClass?: string;
      data?: any;
      size?: string;
    },
    id?: string
  ) => Promise<HTMLIonModalElement>;
}

export function useGwModal() {
  return inject('gwModal') as GwPluginInterface
}

function addModalEventListeners(modal: HTMLIonModalElement, name: string) {
  document.dispatchEvent(new CustomEvent('gwModalPresented', { detail: { page_name: name || 'Unknown Modal' } }))

  modal.onDidDismiss().then((data) => {
    if (data.role === 'backdrop')
      document.dispatchEvent(new CustomEvent('gwModalDismissedByBackdrop', { detail: null }))
  })
}

const GwModalObject = {
  closeModal: async (id: string) => {
    await modalController.dismiss(null, undefined, id)
  },

  closeTop: async (data?: any) => {
    const top = await modalController.getTop()
    if (top && top.canDismiss) {
      await top.dismiss(data)
    }
  },

  enterAnimationDefinition: (baseEl: any) => {
    const root = baseEl.shadowRoot

    // const backdropAnimation = createAnimation()
    // 	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // 	.addElement(root.querySelector('ion-backdrop')!)
    // 	.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

    const wrapperAnimation = createAnimation()
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .addElement(root.querySelector('.modal-wrapper')!)
      .keyframes([
        { offset: 0, opacity: '0', transform: 'scale(1)' },
        { offset: 1, opacity: '1', transform: 'scale(1)' },
      ])

    return createAnimation().addElement(baseEl).easing('ease-out').duration(150).addAnimation([wrapperAnimation])
  },

  getTop: async () => {
    return await modalController.getTop()
  },

  leaveAnimationDefinition: (baseEl: any) => {
    return GwModalObject.enterAnimationDefinition(baseEl).direction('reverse')
  },

  openBottomSheet: async (
    innerComponent: any,
    props: {
      canDismiss?: boolean | ((data?: any, role?: string | undefined) => Promise<boolean>);
      cssClass?: string;
      data?: any;
      size?: string;
    } = {
      canDismiss: true,
      cssClass: '',
      data: undefined,
      size: 'sm',
    },
    id?: string
  ) => {
    const modal = await modalController.create({
      animated: true,
      breakpoints: [0, 1],
      canDismiss: props.canDismiss || true,
      component: () =>
        h(GwBottomSheet, { cssClass: props.cssClass, data: props.data, size: props.size }, {
          default: (slotProps: { slotProps: { content: any; data: any } }) =>
            h(innerComponent, slotProps),
        }),
      cssClass: `gw-modal-sheet${props.cssClass ? ' ' + props.cssClass : ''}`,
      id,
      initialBreakpoint: 1,
      // presentingElement: top || undefined
    })
    await modal.present()
    addModalEventListeners(modal, innerComponent.name)
    return modal
  },

  openModal: async (
    innerComponent: any,
    props: {
      canDismiss?: boolean | ((data?: any, role?: string | undefined) => Promise<boolean>);
      cssClass?: string;
      data?: any;
      size?: string;
    } = {
      canDismiss: true,
      cssClass: '',
      data: undefined,
      size: 'sm',
    },
    id?: string
  ) => {
    const enterAnimationDefinition = (baseEl: any) => {
      const root = baseEl.shadowRoot

      // const backdropAnimation = createAnimation()
      // 	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // 	.addElement(root.querySelector('ion-backdrop')!)
      // 	.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

      const wrapperAnimation = createAnimation()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .addElement(root.querySelector('.modal-wrapper')!)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(1)' },
          { offset: 1, opacity: '1', transform: 'scale(1)' },
        ])

      return createAnimation().addElement(baseEl).easing('ease-out').duration(150).addAnimation([wrapperAnimation])
    }

    const leaveAnimationDefinition = (baseEl: any) => {
      return enterAnimationDefinition(baseEl).direction('reverse')
    }

    const modal = await modalController.create({
      animated: true,
      canDismiss: props.canDismiss || true,
      component: () =>
        h(GwModal, props, {
          default: (slotProps: { slotProps: { content: any; data: any } }) => h(innerComponent, slotProps),
        }),
      cssClass: props.cssClass,
      enterAnimation: enterAnimationDefinition || (() => createAnimation()),
      id,
      leaveAnimation: leaveAnimationDefinition || (() => createAnimation()),
    })

    await modal.present()
    addModalEventListeners(modal, innerComponent.name)
    return modal
  },
}

const GwModalPlugin = {
  install: async (app: App) => {
    app.provide('gwModal', GwModalObject)
  },
}

export default GwModalPlugin
