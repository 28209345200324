import { TGWAnalyticsEvent, TGWAnalyticsEventPayload } from '@/plugins/analytics/types'
import { GWAnalyticsProvider } from '@/plugins/analytics'
import dayjs from 'dayjs'
import { useApi } from '@/plugins/api'
import * as Sentry from '@sentry/vue'
import { useUtm } from '@/composables/utm'

export class GwCustomAnalyticsProvider implements GWAnalyticsProvider {
	private MAX_SESSION_LENGTH = parseInt(process.env.VUE_APP_GW_ANALYTICS_MAX_SESSION_LENGTH)
	private session: { start?: string, utm?: Record<string, string>, uuid?: string } = { start: undefined, utm: undefined, uuid: undefined }
	api = useApi()

	private shouldStartSession() {
		const storedSession = localStorage.getItem('gw_session')
		if (storedSession) {
			this.session = JSON.parse(storedSession)
			if (!this.session.uuid) throw new TypeError('GwSessionInvalidUuidError')
			if (this.session.start) {
				const sessionStartDate = dayjs(this.session.start)
				if (!sessionStartDate.isValid()) throw new TypeError('GwSessionInvalidExpiresDateError')
				else {
					if (dayjs().diff(sessionStartDate, 'minutes') > this.MAX_SESSION_LENGTH || !dayjs().isSame(sessionStartDate, 'day')) {
						return true
					} else {
						return false
					} 
				}
			}
		}

		return true
	}

	async startSession() {
		let shouldStartNew = true
		try {
			shouldStartNew = this.shouldStartSession()
		} catch(err) {
			if (err instanceof TypeError) {
				console.log(err.name, err.message)
				Sentry.captureException(err)
			}
		}

		if (shouldStartNew) {
			const { utm } = useUtm()

			try {
				const { data: { data }} = await this.api.session({ 
					data: {
						...utm.value,
						referrer: document.referrer
					}
				})
				if (data.uuid && this.session.uuid !== data.uuid ) {
					this.session = { start: dayjs().toISOString(), utm: utm.value, uuid: data.uuid }
					localStorage.setItem('gw_session', JSON.stringify(this.session))
				}
			} catch(err) {
				console.error(err)
			}
		}
	}

	track<T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T> | undefined): void {
		let typedData
		switch (event) {
			case 'session': 
				typedData = data as TGWAnalyticsEventPayload<'session'>
				console.log('session', typedData)
				this.startSession()
				break
			default: 
				break
		}
	}
}