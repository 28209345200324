
import { defineComponent, PropType } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'SystemDown',
  props: {
    slotProps: {
      type: Object as PropType<{
        content: any;
        data: any;
      }>,
      default: null,
    },
  },

  setup() {
    const { t } = useI18n()
    return { t }
  }
})
