import { shouldPolyfill as intlLocaleShouldPoly } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as pluralRulesShouldPoly } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as rtFormatShouldPoly } from '@formatjs/intl-relativetimeformat/should-polyfill'

(async () => {
  if (
		'ResizeObserver' in window === false || 
		('ResizeObserverEntry' in window && !('borderBoxSize' in ResizeObserverEntry.prototype))
	) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer')
    window.ResizeObserver = module.ResizeObserver
  }

	if (intlLocaleShouldPoly()) await import('@formatjs/intl-locale/polyfill')

	const unsupportedLocaleEnPlural = pluralRulesShouldPoly('en')
	const unsupportedLocaleSkPlural = pluralRulesShouldPoly('sk')

	// This locale is supported
	if (unsupportedLocaleEnPlural || unsupportedLocaleSkPlural) {
		await import('@formatjs/intl-pluralrules/polyfill-force')
		if (unsupportedLocaleEnPlural) await import('@formatjs/intl-pluralrules/locale-data/en')
		if (unsupportedLocaleSkPlural) await import('@formatjs/intl-pluralrules/locale-data/sk')
	}


	const unsupportedLocaleEn = rtFormatShouldPoly('en')
	const unsupportedLocaleSk = rtFormatShouldPoly('sk')
	// This locale is supported
	if (unsupportedLocaleEn || unsupportedLocaleSk) {
		await import('@formatjs/intl-relativetimeformat/polyfill-force')
		if (unsupportedLocaleEn) await import('@formatjs/intl-relativetimeformat/locale-data/en')
		if (unsupportedLocaleSk) await import('@formatjs/intl-relativetimeformat/locale-data/sk')
	}
	
})()
