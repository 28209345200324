
import { defineComponent, PropType } from 'vue'
import { IonItem, IonLabel, IonCheckbox, IonIcon } from '@ionic/vue'
import { Field } from 'vee-validate'
import { AutocompleteTypes, TextFieldTypes, IonTextareaCustomEvent } from '@ionic/core'
import { eyeOutline, eyeOffOutline } from 'ionicons/icons'
import { useGrid } from 'vue-screen'
import SlotVarWatcher from '@/components/util/SlotVarWatcher.vue'

export default defineComponent({
	name: 'GwInput',

	components: {
		Field,
		IonCheckbox,
		IonIcon,
		// IonInput,
		IonItem,
		IonLabel,
		SlotVarWatcher
	},

	props: {
		autocomplete: { type: String as PropType<AutocompleteTypes>, required: false, default: 'off' },
		label: { type: String, required: true },
		modelValue: { type: [String, Object, Boolean], required: true },
		name: { type: String, required: true },
		options: { type: Array, required: false, default: () => [] as any[] },
		readonly: {type: Boolean, default: false},
		rules: { type: String, default: '' },
		type: { type: String as PropType<TextFieldTypes | 'checkbox' | 'textarea'>, default: 'text' },
		updateOnInput: { type: Boolean, default: false }
	},

	emits: [
		'update:modelValue',
		'custom-valid',
		'custom-error',
		'focus', 'blur', 'keyup-enter', 'valid'
	],

	setup() {
		const grid = useGrid('tailwind')
		return { eyeOffOutline, eyeOutline, grid }
	},

	data() {
		return {
			showPassword: false
		}
	},

	computed: {
		localType(): TextFieldTypes {
			return this.showPassword ? 'text' : 'password'
		},

		localValue: {
			get(): string | Record<string, unknown> | boolean {
				return this.modelValue
			},
			set(value: any) {
				// eslint-disable-next-line no-console
				console.log(value)
			}
		},
	},

	watch: {
		localValue: {
			handler: async function(n, o) {
				await this.$nextTick()
				const input = (this.$refs['input'] as HTMLInputElement) || this.$refs['textarea'] as HTMLTextAreaElement
				const item = (this.$refs['item'] as any)?.$el as HTMLElement
				if (input && n && (!o || !input.value)) {
					if (input.value !== n) input.value = n
					item.classList.add('item-has-value')
				}
			},
			immediate: true
		}
	},

	methods: {
		// onBlur(event: CustomEvent, setTouched: (isTouched: boolean) => void) {
		// 	if ((event.detail as FocusEvent).relatedTarget) {
		// 		setTouched(true)
		// 	}
		// },
		async onBlur(event: Event) {
			this.onIonBlur()
			const item = (this.$refs['item'] as any)?.$el as HTMLElement
			if (item) {
				let classList = ['item-has-focus']
				if (!this.localValue) classList.push('item-has-value')
				else item.classList.add('item-has-value')
				item.classList.remove(...classList)
				await this.$nextTick()
			}
			this.$emit('blur', event)
		},

		async onFocus(event: Event) {
			this.onIonFocus()
			const item = (this.$refs['item'] as any)?.$el as HTMLElement
			if (item) {
				let classList = ['item-has-focus']
				if (this.localValue) classList.push('item-has-value')
				item.classList.add(...classList)
				await this.$nextTick()
			}
			this.$emit('focus', event)
		},

		onIonBlur() {
			if (this.$store.state.isIosPwa) {
				this.$store.commit('inputFocusChanged', false)
			}
		},

		onIonFocus(event?: IonTextareaCustomEvent<FocusEvent>) {
			if (this.$store.state.isIosPwa) {
				this.$store.commit('inputFocusChanged', true)
			}

			if (event) {
				let targetBottom = event.target.getBoundingClientRect().bottom
				const currentViewport = window.visualViewport
				if (!currentViewport) return
				const listener = async (ev: Event) => {
					if ((ev.target as VisualViewport).height < targetBottom) {
						window.scrollBy({
							behavior: 'smooth',
							top: targetBottom - (ev.target as VisualViewport).height,
						})
					}
					currentViewport.removeEventListener('resize', listener)
				}

				currentViewport.addEventListener('resize', listener)
			}
		},

		onItemClick() {
			const input = (this.$refs['input'] as HTMLInputElement) || this.$refs['textarea'] as HTMLTextAreaElement
			input?.focus()
		},

		onUpdate(value: any) { //handleChange: (arg0: any) => void
			// handleChange(value)
			this.$emit('update:modelValue', value)
		},

		onValidChanged(value: boolean) {
			this.$emit('valid', value)
		}
	}
})
