import { IonContent } from '@ionic/vue'

export function useIonContent() {
	const scrollIonContentToElement = async (content: InstanceType<typeof IonContent>, element: HTMLElement, scrollOffsetTop = 16, scrollTime = 350) => {
		const scrollEl = await (content.$el as HTMLIonContentElement).getScrollElement() as HTMLElement
		const contentTop = scrollEl.offsetTop
		const inputTop = element.offsetTop
		if (content.$el) await content.$el.scrollToPoint(0, inputTop - contentTop - scrollOffsetTop, scrollTime)
	}

	return {
		scrollIonContentToElement
	}
}