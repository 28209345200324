import { ref } from 'vue'
import { useGrid } from 'vue-screen'
import { ModalType, useEvents } from './events'

export function useModal(type: ModalType) {
	const grid = useGrid('tailwind')
	const modalData = ref<any>()
	const modalOpen = ref(false)
	const modalType = ref<'bottomSheet' | 'overlay'>(grid.breakpoint ? 'overlay' : 'bottomSheet')
	const { modalEventBus } = useEvents()
	const open = () => {
		modalType.value = grid.breakpoint ? 'overlay' : 'bottomSheet'
		modalOpen.value = true
	}
	const close = (data?: any) =>  modalEventBus.emit(modalType.value === 'bottomSheet' ? 'closed' : 'close', { data, type })
	const onModalWillDismiss = (data?: any) => close(data)
	const onModalDismiss = (data?: any) => {
		modalEventBus.emit('closed', { data, type })
	}
	modalEventBus.on((event, payload) => {
		if (payload?.type !== type) return
		if (event === 'open') {
			if (payload.data) modalData.value = payload.data
			open() 
		}
		else if (event === 'close' || event === 'closed') modalOpen.value = false
	})

	return {
		close,
		modalData,
		modalOpen,
		modalType,
		onModalDismiss, 
		onModalWillDismiss
	}
}