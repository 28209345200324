import { App, inject } from 'vue'
import { getPlatforms, modalController } from '@ionic/vue'
import { TGWAnalyticsEvent, TGWAnalyticsEventPayload } from './types'

export function useGWAnalytics() {
  return inject('gwAnalytics') as typeof GWAnalytics
}

export interface GWAnalyticsProvider {
	track<T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T>): void
}

class GWAnalyticsFacade {
	private providers: Map<string, GWAnalyticsProvider> = new Map([])

	registerProvider(name: string, provider: GWAnalyticsProvider) {
		this.providers.set(name, provider)
	}

	track<T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T>) {
		this.providers.forEach(provider => provider.track(event, data))
	}
}

export const GWAnalytics = {
	handleClick: async (el: HTMLElement, binding: any) => {
    const top = await modalController.getTop()

		GWAnalytics.track('click', {
			language: localStorage.getItem('gw_locale') || '',
			name: el.innerText || '',
			page: top && top.id ? top.id : binding.value.name?.toString() || '',
			path: binding.value.fullPath || '',
			platform: getPlatforms().join(', ' )
		})
  },
	handleEvent: (el: HTMLElement, binding: any) => {
    return function() {
      GWAnalytics.handleClick(el, binding)
    }
  },
	track: <T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T>) => GWAnalytics.tracker.track(event, data),
	tracker: new GWAnalyticsFacade(),
	vAnalyticsClick: {
		mounted: function(el: HTMLElement, binding: any) {
			el.addEventListener('click', GWAnalytics.handleEvent(el, binding))
		},
	
		unmounted: (el: HTMLElement, binding: any) => {
			el.removeEventListener('click', GWAnalytics.handleEvent(el, binding))
		}
	}
}

const GWAnalyticsPlugin = {
  install: (app: App) => app.provide('gwAnalytics', GWAnalytics)
}

export default GWAnalyticsPlugin