import * as Sentry from '@sentry/vue'
import { App } from 'vue'

export const sentry = (app: App<Element>): void => {
	Sentry.init({
		app,
		dsn: 'https://f771698570f84ed3b86a7a95bdfd7f17@o4504519685046272.ingest.sentry.io/4504519752351744',
		logErrors: true,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.2
	})
}
