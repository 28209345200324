import { createApp } from 'vue'
import App from './App.vue'
// api
import store from './store'
import api from './plugins/api' 
import router from './router'
import { IonicVue, createAnimation } from '@ionic/vue'
import { setupCalendar } from 'v-calendar'
import './registerServiceWorker'

import './polyfills'

// translations
import i18n from './plugins/i18n'

// validate
import './plugins/validate'
import { setLocale } from '@vee-validate/i18n'
setLocale('sk')

// mixpanel
import Mixpanel from './plugins/mixpanel/mixpanel'

// gw modal
import GwModalPlugin from './plugins/gw-modal'
import '@/plugins/gw-modal/styles.css'

// gw analytics
import GWAnalyticsPlugin from './plugins/analytics'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
// import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
// import '@ionic/vue/css/padding.css'
// import '@ionic/vue/css/float-elements.css'
// import '@ionic/vue/css/text-alignment.css'
// import '@ionic/vue/css/text-transformation.css'
// import '@ionic/vue/css/flex-utils.css'
// import '@ionic/vue/css/display.css'

import './theme/fonts.css'
/* Theme variables */
import './theme/variables.css'
import './theme/index.css'
import './theme/animations.css'
import './theme/ionic.scss'
import './theme/generated.css'

const app = createApp(App)
  .use(i18n)
  .use(IonicVue, {
    mode: 'ios',
    navAnimation: () => createAnimation()
  })
  .use(setupCalendar, {})
  .use(Mixpanel, { 
    debug: process.env.NODE_ENV !== 'production',
    token: process.env.VUE_APP_MIXPANEL_TOKEN 
  })
  .use(api)
  .use(store)
  .use(router)
  .use(GwModalPlugin)
  .use(GWAnalyticsPlugin)

import { createGtm } from '@gtm-support/vue-gtm'
// import PlayerZero from '@goplayerzero/sdk-web'
if (process.env.VUE_APP_GTM_ID) {
  app.use(
    createGtm({
      debug: process.env.NODE_ENV !== 'production',
      defer: true,
      id: process.env.VUE_APP_GTM_ID,
      vueRouter: router
    })
  )
}
import { sentry } from './plugins/sentry'
if (process.env.NODE_ENV === 'production') {
  sentry(app)
  
  // if (process.env.VUE_APP_PLAYERZERO) {
  //   PlayerZero.init(process.env.VUE_APP_PLAYERZERO)
  // }
}

router.isReady().then(() => {
  app.mount('#app')
})

export default app

// Element.prototype.animate = function() { return new Animation(null, null) } 
