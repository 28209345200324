/* eslint-disable vue/sort-keys */
import { RouteRecordRaw } from 'vue-router'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import store from '@/store'

const AddOrderToCalendar = () => import('@/views/AddOrderToCalendar.vue')
const CancelOrder = () => import('@/views/CancelOrder.vue')
const EvaluateOrder = () => import('@/views/EvaluateOrder.vue')
const NotFound = () => import('@/views/NotFound.vue')
const OrderForm = () => import('@/views/OrderForm.vue')
const OrderList = () => import('@/views/OrderList.vue')
const SetPassword = () => import('@/views/SetPassword.vue')

const isLoggedInGuard = (to: any, from: any, next: any) => {
  if (!store.getters.isLoggedIn) {
    next('/')
  } else {
    next()
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Order',
    component: OrderForm,
    props: true
  },
  {
    path: '/set-password/:email/:code',
    name: 'SetPassword',
    component: SetPassword
  },
  {
    path: '/cancel-order/:orderId/:date/:time/:code',
    name: 'CancelOrder',
    component: CancelOrder
  },
  {
    path: '/rate-order/:orderId/:code',
    name: 'EvaluateOrder',
    component:EvaluateOrder
  },
  {
    path: '/add-order-to-calendar/:orderId/:code',
    name: 'AddOrderToCalendar',
    component: AddOrderToCalendar
  },
  {
    path: '/zoznam',
    name: 'OrderList',
    component: OrderList,
    beforeEnter: isLoggedInGuard
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
