import { Mixpanel } from '@/plugins/mixpanel/mixpanel'
import { GWAnalyticsProvider } from '@/plugins/analytics'
import { TGWAnalyticsEvent, TGWAnalyticsEventPayload } from '@/plugins/analytics/types'


export class MixpanelAnalyticsProvider implements GWAnalyticsProvider {
	mixpanel: ReturnType<typeof Mixpanel>

	constructor(mixpanel: ReturnType<typeof Mixpanel>) {
		this.mixpanel = mixpanel
	}

	track<T extends TGWAnalyticsEvent>(event: T, data?: TGWAnalyticsEventPayload<T>): void {
		let typedData
		switch(event) {
			case 'click':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'click'>
				this.mixpanel.buttonClick({
					language: typedData.language || '',
					name: typedData.name || '',
					page: typedData.page || '',
					page_suffix: typedData.page_suffix || '',
					path: typedData?.path || '',
					platform: typedData.platform || ''
				})
				break

			case 'contact_click':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'contact_click'>
				this.mixpanel.buttonClick({
					language: typedData.language || '',
					name: `${event} - ${typedData.type}: ${typedData.number}`,
					page: typedData.page || '',
					path: typedData?.path || '',
					platform: typedData.platform || ''
				})
				break

			case 'form_error':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'form_error'>
				this.mixpanel.alert({
					element: typedData.form_name,
					language: typedData.language || '',
					page: typedData.page || '',
					path: typedData.path || '',
					platform: data.platform || ''
				})
				break

			case 'login':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'login'>
				this.mixpanel.login(typedData.user)
				break

			case 'logout':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'logout'>
				this.mixpanel.logout(typedData.user)
				break

			case 'order_created':
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'order_created'>
				this.mixpanel.createOrder(typedData.order)
				break

			case 'page_view':
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'page_view'>
				this.mixpanel.pageView({
					language: typedData.language,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
				})
				break

			case 'signup':
				if (!data) return 
				typedData = data as TGWAnalyticsEventPayload<'signup'>
				this.mixpanel.signUp(typedData.user)
				break

			case 'step_view':
				if (!data) return
				typedData = data as TGWAnalyticsEventPayload<'step_view'>
				this.mixpanel.stepView({
					language: typedData.language,
					page: typedData.page,
					path: typedData.path,
					platform: typedData.platform,
					step_name: typedData.step_name,
					step_number: typedData.step_number,
					value: typedData.value
				})
				break
			default: return
		}
	}
}