export class User {
  create_account?: boolean
  email: string
  id?: number
  is_activated: boolean
  is_guest: boolean
  marketing_agreement: boolean
  name: string
  phone_number: string
  surname: string
  tos_agreement: boolean
  reset_password_code?: string

  constructor(
    name?: string,
    surname?: string,
    email?: string,
    phone_number?: string,
    id?: number,
    is_activated?: boolean,
    is_guest?: boolean,
    marketing_agreement?: boolean,
    tos_agreement?: boolean
  ) {
    this.name = name || ''
    this.surname = surname || ''
    this.email = email || ''
    this.phone_number = phone_number || ''
    this.id = id || undefined
    this.is_activated = is_activated || false
    this.is_guest = is_guest || false
    this.marketing_agreement = marketing_agreement || false
    this.tos_agreement = tos_agreement || false
  }
}
