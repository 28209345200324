import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export function useUtm() {
	const route = useRoute()
	const utm = ref<Record<string, string>>({})

	watch(
		() => route.query,
		(params) => {
			if (!params) return
			Object.entries(params).forEach(([key, value]) => {
				if (key.startsWith('utm_')) utm.value[key] = value as string
			})
		},
		{ deep: true, immediate: true }
	)

	return {
		utm
	}
}