import '@vee-validate/i18n/dist/locale/sk.json'
import '@vee-validate/i18n/dist/locale/en.json'
import { defineRule, configure } from 'vee-validate'
import { required, email, min } from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

defineRule('required', required)
defineRule('email', email)
defineRule('confirmed', (value: any, [target]: any) => {
  return value === target
})
defineRule('min', min)

configure({
  generateMessage: localize({
    en: {
      messages: {
        confirmed: 'Passwords do not match',
        email: 'Email is not in correct format',  
        min: '{field} requires at least 0:{min} symbols',
        required: 'This field is required'
      },
      names: {
        password: 'Password',
      }
    },
    sk: {
      messages: {
        confirmed: 'Heslá sa nezhodujú',
        email: 'E-mail nie je správneho tvaru',  
        min: '{field} musí mať aspoň 0:{min} znakov',
        required: 'Toto pole je povinné',
      },
      names: {
        password: 'Heslo',
      }
    },
  }),
})
