import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, withKeys as _withKeys, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["name", "value", "onInput", "onBlur", "onChange"]
const _hoisted_4 = ["value", "autocomplete", "type", "name", "readonly", "onInput", "onChange"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlotVarWatcher = _resolveComponent("SlotVarWatcher")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'mb-3.5': _ctx.type === 'checkbox' })
  }, [
    _createVNode(_component_Field, {
      modelValue: _ctx.localValue,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue) = $event)),
      name: _ctx.name,
      rules: _ctx.rules
    }, {
      default: _withCtx(({ value, errors, setTouched, meta }) => [
        (_ctx.updateOnInput)
          ? (_openBlock(), _createBlock(_component_SlotVarWatcher, {
              key: 0,
              modelValue: meta.valid,
              "onUpdate:modelValue": ($event: any) => ((meta.valid) = $event),
              onChanged: _ctx.onValidChanged
            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChanged"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_item, {
          ref: "item",
          lines: "none",
          class: _normalizeClass(["[--ripple-color:transparent] [--background-activated:transparent]", {
					'-checkbox-item text-sm text-mainDark [--inner-padding-end:0]': _ctx.type === 'checkbox',
					'border rounded-md [--border-radius:theme(borderRadius.md)]': _ctx.type !== 'checkbox',
					'border-lightGrey hover:border-primaryBorder gw-valid-item': _ctx.type !== 'checkbox' && (!errors.length || !meta.touched),
					'border-[#F20000]': _ctx.type !== 'checkbox' && ((meta.touched || meta.dirty) && errors.length),
					'relative': _ctx.type === 'password'
				}]),
          onClick: _withModifiers(_ctx.onItemClick, ["prevent"])
        }, {
          default: _withCtx(() => [
            (_ctx.type !== 'checkbox')
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  ref: "label",
                  position: "floating"
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "label", {}, () => [
                      _createTextVNode(_toDisplayString(_ctx.label), 1)
                    ]),
                    (_ctx.rules.indexOf('required') !== -1)
                      ? (_openBlock(), _createElementBlock("sup", _hoisted_1, "*"))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }, 512))
              : _createCommentVNode("", true),
            (_ctx.type === 'checkbox')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_ion_checkbox, {
                    slot: !_ctx.grid.breakpoint ? 'end' : 'start',
                    name: _ctx.name,
                    "model-value": (value as string | boolean | undefined),
                    class: "mr-0 sm:mr-2.5 my-0 [--size:1.5rem] sm:[--size:1.125rem]",
                    onIonBlur: ($event: any) => (setTouched(true)),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUpdate($event)))
                  }, null, 8, ["slot", "name", "model-value", "onIonBlur"]),
                  _createVNode(_component_ion_label, { class: "my-0 mr-4 sm:mr-2" }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "label", {}, () => [
                        _createTextVNode(_toDisplayString(_ctx.label), 1)
                      ]),
                      (_ctx.rules.indexOf('required') !== -1)
                        ? (_openBlock(), _createElementBlock("sup", _hoisted_2, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  })
                ], 64))
              : (_ctx.type === 'textarea')
                ? (_openBlock(), _createElementBlock("textarea", {
                    key: 2,
                    ref: "textarea",
                    name: _ctx.name,
                    value: (value as string | number | string[] | undefined),
                    class: "text-sm text-mainDark w-full outline-none mt-2 pb-2 bg-transparent resize-none",
                    rows: 3,
                    onInput: ($event) => { _ctx.updateOnInput && _ctx.onUpdate(($event.target as HTMLInputElement).value) },
                    onBlur: ($event: any) => {_ctx.onBlur($event); setTouched(true)},
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onFocus($event))),
                    onChange: ($event: any) => (_ctx.onUpdate(($event.target as HTMLTextAreaElement).value))
                  }, null, 40, _hoisted_3))
                : (_openBlock(), _createElementBlock("input", {
                    key: 3,
                    ref: "input",
                    value: value,
                    class: _normalizeClass(["text-sm text-mainDark w-full outline-none py-2", {
							'[--padding-end:1.875rem]': _ctx.type === 'password'
						}]),
                    autocomplete: _ctx.autocomplete,
                    type: _ctx.type === 'password' ? _ctx.localType : _ctx.type,
                    name: _ctx.name,
                    readonly: _ctx.readonly,
                    onInput: ($event) => { _ctx.updateOnInput && _ctx.onUpdate(($event.target as HTMLInputElement).value) },
                    onChange: ($event: any) => (_ctx.onUpdate(($event.target as HTMLInputElement).value)),
                    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onFocus($event))),
                    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onBlur($event))),
                    onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.$emit('keyup-enter')), ["enter"]))
                  }, null, 42, _hoisted_4)),
            (_ctx.type === 'password')
              ? (_openBlock(), _createBlock(_component_ion_icon, {
                  key: 4,
                  icon: _ctx.showPassword ? _ctx.eyeOffOutline : _ctx.eyeOutline,
                  class: "absolute top-1/2 right-2.5 -translate-y-1/2 text-xl text-primary cursor-pointer z-10 transition-all",
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.showPassword = !_ctx.showPassword), ["prevent","stop"]))
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class", "onClick"]),
        ((meta.touched || meta.dirty) && errors.length)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["gw-error", { 'ml-0 sm:ml-7': _ctx.type === 'checkbox' }])
            }, _toDisplayString(errors[0]), 3))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["modelValue", "name", "rules"])
  ], 2))
}