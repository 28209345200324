import { getPlatforms, modalController } from '@ionic/vue'
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser'
/* eslint-disable vue/sort-keys */
import { App, inject } from 'vue'
import { OrderReservation } from '@/models/order'
import { User } from '@/models/user'

export function useMixpanel() {
  return inject('mixpanel') as ReturnType<typeof Mixpanel>
}

export const Mixpanel = function(mixpanel: OverridedMixpanel) {
  const handleClick = async (el: HTMLElement, binding: any) => {
    const top = await modalController.getTop()
    
    mixpanel.track('Button Click', {
      'Button Name': el.innerText,
      'Platform': getPlatforms().join(', ' ),
      'Page Name': top && top.id ? top.id : binding.value.name?.toString() || '',
      'Pathname': binding.value.fullPath,
      'Language': localStorage.getItem('gw_locale')
    })
  }

  const handleEvent = (el: HTMLElement, binding: any) => {
    return function() {
      handleClick(el, binding)
    }
  }

  return {
    signUp(user: User) {
      mixpanel.alias(`${user.id}`)
  
      mixpanel.track('Sign Up', {
        'Sign Up Date': new Date().toISOString(),
        'Sign Up User Id': user.id,
        'Sign Up Source': 'FE',
      })
  
      mixpanel.people.set({
        $distinct_id: user.id,
      })
    },

    login(user: User) {
      mixpanel.identify(`${user.id}`)
  
      mixpanel.track('Login', {
        'Login Date': new Date().toISOString(),
        'Login User Id': user.id,
      })
  
      mixpanel.people.set_once({
        'Login First Date': new Date().toISOString(),
      })
  
      mixpanel.people.set({
        'Login Last Date': new Date().toISOString(),
      })
    },
  
    logout(user: User) {
      mixpanel.identify(`${user.id}`)
  
      mixpanel.track('Logout', {
        'Logout User Id': user.id,
        'Logout Date': new Date().toISOString(),
      })
  
      mixpanel.people.set_once({
        'Logout First Date': new Date().toISOString(),
      })
  
      mixpanel.people.set({
        'Logout Last Date': new Date().toISOString(),
      })
  
      mixpanel.reset()
    },
  
    createOrder(data: OrderReservation) {
      mixpanel.identify(`${data.user?.id}`)
  
      mixpanel.track('Create Order', {
        'Create Order User Id': data.user?.id,
        'Create Order Date': new Date().toISOString(),
        'Create Order Source': 'FE',
        'Create Order Order Id': data.id,
        'Create Order Store Id': data.store.id,
        'Create Order Store Name': data.store.name,
        'Create Order Service Id': data.service.id,
        'Create Order Service Name': data.service.name,
        'Create Order Service Price': data.service_price,
        'Create Order Barber Id': data.barber.id,
        'Create Order Barber Name': `${data.barber.first_name} ${data.barber.last_name}`,
        'Create Order Barber Service Time': data.duration,
        'Create Order Slot Date': new Date(data.reservation_datetime).toISOString(),
        'Create Order Note': data.note,
        'Create Order User VOP': data.user?.tos_agreement,
        'Create Order User Marketing': data.user?.marketing_agreement,
        'Create Order User New Account': data.user?.create_account,
        'Create Order User Is Client': !data.user?.is_guest,
      })
  
      mixpanel.people.set_once({
        'Create Order First Date': new Date().toISOString(),
      })
  
      mixpanel.people.set({
        'Create Order Last Date': new Date().toISOString(),
      })
  
      mixpanel.people.increment('Created Orders')
    },
  
    cancelOrder(data: OrderReservation) {
      mixpanel.identify(`${data.user?.id}`)
  
      mixpanel.track('Cancel Order', {
        'Cancel Order User Id': data.user?.id,
        'Cancel Order Date': new Date().toISOString(),
        'Cancel Order Source': 'FE',
        'Cancel Order Order Id': data.id,
        'Cancel Order Store Id': data.store.id,
        'Cancel Order Store Name': data.store.name,
        'Cancel Order Service Id': data.service.id,
        'Cancel Order Service Name': data.service.name,
        'Cancel Order Service Price': data.service_price,
        'Cancel Order Barber Id': data.barber.id,
        'Create Order Barber Name': `${data.barber.first_name} ${data.barber.last_name}`,
        'Cancel Order Slot Date': new Date(data.reservation_datetime).toISOString(),
        'Cancel Order Note': data.note,
        'Cancel Order Reason': 'user_cancelation',
      })
  
      mixpanel.people.set_once({
        'Cancel Order First Date': new Date().toISOString(),
      })
  
      mixpanel.people.set({
        'Cancel Order Last Date': new Date().toISOString(),
      })
  
      mixpanel.people.increment('Canceled Orders')
    },
  
    pageView(data: { language: string; page: string; path: string; platform: string; }) {
      mixpanel.track('Page View', {
        'Page Name': data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Language': data.language
      })
    },
  
    stepView(data: {
      language: string;
      page: string;
      path: string;
      platform: string;
      step_name: string;
      step_number: string;
      value: string;
    }) {
      mixpanel.track('Step View', {
        'Language': data.language,
        'Step #': data.step_number,
        'Page Name': data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Step Name': data.step_name,
        'Value': data.value
      })
    },

    evaluationStep(data: {
      comment?: string;
      language: string;
      page: string;
      path: string;
      platform: string;
      rating: number;
      step_number: number;
      tags?: string;
      type: 'barber' | 'store';
    }) {
      mixpanel.track('Evaluation Step', {
        'Comment': data.comment || '',
        'Language': data.language,
        'Page Name': data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Rating': data.rating,
        'Step #': data.step_number,
        'Tags': data.tags || '',
        'Type': data.type
      })
    },

    evaluation(data: {
      barberComment: string;
      barberRating: number;
      barberTags: string;
      language: string;
      order: OrderReservation;
      page: string;
      path: string;
      platform: string;
      storeComment: string;
      storeRating: number;
      storeTags: string;
    }) {
      mixpanel.identify(data.order.user ? '' + data.order.user.id : undefined)
      mixpanel.track('Evaluation', {
        'Barber Comment': data.barberComment,
        'Barber Rating': data.barberRating,
        'Barber Tags': data.barberTags,
        'Evaluation Barber Id': data.order.barber.id,
        'Evaluation Barber Name': `${data.order.barber.first_name}${data.order.barber.last_name ? ' ' + data.order.barber.last_name : '' }`,
        'Evaluation Order Id': data.order.id,
        'Evaluation Order Note': data.order.note,
        'Evaluation Store Id': data.order.store.id,
        'Evaluation Store Name': data.order.store.name,
        'Evaluation Service Id': data.order.service.id,
        'Evaluation Service Name': data.order.service.name,
        'Evaluation Service Price': data.order.service_price,
        'Evaluation Service Time': data.order.duration,
        'Evaluation Slot Date': new Date(data.order.reservation_datetime).toISOString(),
        'Evaluation User Id': data.order.user?.id,
        'Language': data.language,
        'Page Name': data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Store Comment': data.storeComment,
        'Store Rating': data.storeRating,
        'Store Tags': data.storeTags
      })
    },
  
    async buttonClick(data: {
      language: string;
      name: string;
      page: string;
      page_suffix?: string;
      path: string;
      platform: string;
    }) {
      const top = await modalController.getTop()
      mixpanel.track('Button Click', {
        'Button Name': data.name,
        'Platform': data.platform,
        'Page Name': `${top && top.id ? top.id : data.page}${data.page_suffix || ''}`,
        'Pathname': data.path,
        'Language': data.language
      })
    },
  
    async passwordSet(data: { language: string; page: string; path: string; platform: string; }) {
      const top = await modalController.getTop()
      mixpanel.track('Password Set', {
        'Page Name': top && top.id ? top.id : data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Language': data.language
      })
    },
  
    async alert(data: { element: string; language: string; page: string; path: string; platform: string; }) {
      const top = await modalController.getTop()
      mixpanel.track('Alert', {
        'Element Name': data.element,
        'Page Name': top && top.id ? top.id : data.page,
        'Pathname': data.path,
        'Platform': data.platform,
        'Language': data.language
      })
    },

    vMixpanelClick: {
      mounted: function(el: HTMLElement, binding: any) {
        el.addEventListener('click', handleEvent(el, binding))
      },
    
      unmounted: (el: HTMLElement, binding: any) => {
        el.removeEventListener('click', handleEvent(el, binding))
      }
    }
  }
}

const MixpanelPlugin = {
  install: (app: App, options: { debug: boolean; token: string }) => {
    // const mixpanel = await import('mixpanel-browser')
    mixpanel.init(options.token, { 
      debug: options.debug, 
      // record_sessions_percent: 10,
      track_pageview: 'full-url' 
    })
    app.provide('mixpanel', Mixpanel(mixpanel))
  }
}

export default MixpanelPlugin
