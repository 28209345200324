import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import sk from '@/locales/sk.json'

export type MessagesSchema = typeof sk | typeof en

// Util to find missing keys in EN that are in SK
// console.log(Object.keys(sk).filter(k => !Object.keys(en).find(enK => enK === k)))


export default createI18n({
  legacy: false,
  locale: 'sk',
  messages: {
    sk,
    // eslint-disable-next-line vue/sort-keys
    en
  },
  pluralRules: {
    'sk': function(choice) {
      if (choice === 0) return 0
      else if (choice === 1) return 1
      else if (choice > 2 && choice < 5) return 2
      else return 3
    }
  }
})
